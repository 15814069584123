import { createContext, useContext, useEffect, useState } from "react";
import { ERROR_TOAST_TIMEOUT } from "../Common/constants";
import { getTranslations } from "../Common/utils";
import { ToastMessage } from "../ReusableComponents";

const ErrorToastContext = createContext({
  show: false,
  setShow: () => {},
});

export function ErrorToastProvider({ children }) {
  const [show, setShow] = useState(false);
  const value = { show, setShow };
  useEffect(() => {
    if (show) {
      setTimeout(() => {
        setShow(false);
      }, ERROR_TOAST_TIMEOUT);
    }
  }, [show]);
  return (
    <ErrorToastContext.Provider value={value}>
      {show && (
        <ToastMessage
          toastText={getTranslations("error_generic")}
          toastType="danger"
        />
      )}
      {children}
    </ErrorToastContext.Provider>
  );
}

export function useErrorToast() {
  const context = useContext(ErrorToastContext);
  if (!context) {
    throw new Error("useErrorToast must be used within ErrorToastContext");
  }
  return context;
}
