import routes from "../../router/paths";
// import { emojiRegex } from "./emoji-regex";

const get = (obj, path, defValue) => {
  // If path is not defined or it has false value
  if (!path) return undefined;
  // Check if path is string or array. Regex : ensure that we do not have '.' and brackets.
  // Regex explained: https://regexr.com/58j0k
  const pathArray = Array.isArray(path) ? path : path.match(/([^[.\]])+/g);
  // Find value
  const result = pathArray.reduce(
    (prevObj, key) => prevObj && prevObj[key],
    obj
  );
  // If found value is undefined return default value; otherwise return the value
  return result === undefined ? defValue : result;
};

// export const isMobile = () => {
//   return /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
//     navigator.userAgent
//   );
// };

export const isMobile = () => {
  return /iphone|ipod|coreappios|android|nokia|blackberry|BB10|bada|tizen|mini|windows\sce|palm/i.test(
    navigator.userAgent.toLowerCase()
  );
};

export const isTablet = () => {
  return /Tablet|Trident|iPad/i.test(navigator.userAgent);
};

export const sortBy = (arrayOfObject, key, type, format = "asec") => {
  if (!arrayOfObject) return;
  switch (type) {
    case "number":
    case "num": {
      const numArr = arrayOfObject.sort(
        (a, b) => parseFloat(a[key]) - parseFloat(b[key])
      );
      return format === "desc" ? numArr.reverse() : numArr;
    }
    case "str":
    case "string": {
      const strSorted = arrayOfObject.sort((a, b) =>
        a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0
      );
      return format === "desc" ? strSorted.reverse() : strSorted;
    }
    default:
      return;
  }
};

export const getTranslations = (key, defaultValue, type) => {
  try {
    const translation = window.si_gaming_wordle_translation;
    let value = defaultValue;
    if (translation && get(translation, [key], false)) {
      value = get(translation, [key], defaultValue);
    }
    if (type) {
      switch (type) {
        case "caps":
          value = value.toUpperCase();
          break;

        case "lowerCase":
          value = value.toLowerCase();
          break;

        case "capitalization":
          value = value.toLowerCase();
          value = value.charAt(0).toUpperCase() + value.slice(1);
          break;

        case "titleCase":
          value = value
            .toLowerCase()
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");
          break;
        default:
          break;
      }
    }
    return value;
  } catch (e) {
    return defaultValue;
  }
};

export const getCookie = (c_name) => {
  let i,
    x,
    y,
    cookiesArray = document.cookie.split(";");
  for (i = 0; i < cookiesArray.length; i++) {
    x = cookiesArray[i].substr(0, cookiesArray[i].indexOf("="));
    y = cookiesArray[i].substr(cookiesArray[i].indexOf("=") + 1);
    x = x.replace(/^\s+|\s+$/g, "");
    if (x === c_name) {
      return window.decodeURIComponent(y);
    }
  }
  return false;
};

export function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + exdays);
  let expires = "expires=" + d.toGMTString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function delete_cookie(name) {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}

export const isWebview = () => {
  return document.location.href.includes("webview=true");
};

export const getBaseName = (
  path = "/",
  addLeadingSlash = true,
  addTrailingSlash = false
) => {
  try {
    const paths = path?.split("/");
    const leadingPath = addLeadingSlash ? "/" : "";
    const trailingPath = addTrailingSlash ? "/" : "";
    return paths?.length > 1 &&
      paths[1] === process.env.REACT_APP_ROUTER_WEBVIEW_BASE_NAME
      ? trailingPath +
          process.env.REACT_APP_ROUTER_WEBVIEW_BASE_NAME +
          leadingPath
      : process.env.REACT_APP_ROUTER_BASE_NAME;
  } catch (error) {
    return process.env.REACT_APP_ROUTER_BASE_NAME;
  }
};

export const day_keys = [
  "day_sunday",
  "day_monday",
  "day_tuesday",
  "day_wednesday",
  "day_thursday",
  "day_friday",
  "day_saturday",
];

export const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const decodeName = (name) => {
  try {
    if (!name) return "";
    return decodeURIComponent(name.replace(/\+/g, " "));
  } catch (error) {
    return name;
  }
};

export const getQueryParameters = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = new Proxy(urlSearchParams, {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  return params;
};

export const getPath = (route, params = {}) => {
  let slug = routes[route].path;
  if (routes[route].parent) {
    slug = `${routes[route].parent}/${slug}`;
  }
  for (const key in params) {
    if (Object.hasOwnProperty.call(params, key)) {
      const value = params[key];
      slug = slug.replace(`:${key}`, value);
    }
  }
  // console.log(params, `/${translation?.language}/${slug}`);
  return "/" + slug;
};

export const copyToClipboard = (id) => {
  let text = document.getElementById(id).innerHTML;
  navigator.clipboard.writeText(text);
  // alert("Copied to Clipboard");
};

export const scrollToTop = ({ top = 100 }) => {
  window.scroll({
    top: top,
    left: 100,
    behavior: "smooth",
  });
};

export const nFormatter = (num, digits = 0) => {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
    : "0";
};

export const handleF1Login = (
  extraParams = {},
  type = "login",
  translationState,
  userState
) => {
  const F1_LOGIN_BASE_URL = `${process.env.REACT_APP_F1_API_URL}/#/en/${type}?`;
  const webviewRedirect =
    window.location.origin +
    window.location.pathname.replace(
      translationState?.language,
      `${translationState?.language}/webview`
    );
  const F1_LOGIN_PARAMS = {
    redirect: decodeURIComponent(
      userState?.webview ? webviewRedirect : window.location.href
    ),
    lead_source: "web_fantasy",
    ...extraParams,
  };
  const params = new URLSearchParams(F1_LOGIN_PARAMS);
  const url = `${F1_LOGIN_BASE_URL}${params}`;

  window.location.href = url;
};

export const formatTime = (time) => {
  return time < 10 ? "0" + time : time;
};

export const getValue = (value, defaultValue = "-") => {
  return value ? value : defaultValue;
};

const getDayMonthTranslationKey = (value) => {
  const driverStatsModalKey = "driver_stats_modal_";
  const dateMonthObject = {
    Jan: driverStatsModalKey + "january",
    Feb: driverStatsModalKey + "february",
    Mar: driverStatsModalKey + "march",
    Apr: driverStatsModalKey + "april",
    May: driverStatsModalKey + "may",
    Jun: driverStatsModalKey + "june",
    Jul: driverStatsModalKey + "july",
    Aug: driverStatsModalKey + "august",
    Sep: driverStatsModalKey + "september",
    Oct: driverStatsModalKey + "october",
    Nov: driverStatsModalKey + "november",
    Dec: driverStatsModalKey + "december",
    Sun: driverStatsModalKey + "sunday",
    Mon: driverStatsModalKey + "monday",
    Tue: driverStatsModalKey + "tuesday",
    Wed: driverStatsModalKey + "wednesday",
    Thu: driverStatsModalKey + "thursday",
    Fri: driverStatsModalKey + "friday",
    Sat: driverStatsModalKey + "saturday",
  };

  return dateMonthObject[value];
};

export const getFormattedDate = (dateObj) => {
  const day = dateObj.getDay();
  const date = dateObj.getDate();
  const month = dateObj.getMonth();
  const year = dateObj.getFullYear();
  const hour = dateObj.getHours();
  const minutes = dateObj.getMinutes();

  const dayStrings = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const monthStrings = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const formattedDate = date <= 9 ? `0${date}` : date;
  const formattedHour = hour <= 9 ? `0${hour}` : hour;
  const formattedMinutes = minutes <= 9 ? `0${minutes}` : minutes;
  const formattedDay = getTranslations(
    getDayMonthTranslationKey(dayStrings[day]),
    dayStrings[day]
  );
  const formattedMonth = getTranslations(
    getDayMonthTranslationKey(monthStrings[month]),
    monthStrings[month]
  );

  return `${formattedDay}, ${formattedDate} ${formattedMonth}, ${year} ${formattedHour}:${formattedMinutes}`;
};

export const capitalizeFirstLetter = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const getDateISO8601Format = (dateString) => {
  if (!dateString) {
    return new Date();
  }
  let dateParts = dateString?.split(" ");
  let datePart = dateParts?.[0]?.split("/");
  const [month, date, year] = datePart;
  const formattedDatePart = `${year}-${month}-${date}`;
  let timePart = dateParts?.[1];
  let isoDateString = formattedDatePart + "T" + timePart;
  return isoDateString;
};

// export const disableEmojiInput = (value) => {
//   // Replace any emoji characters with an empty string
//   value = value.replace(emojiRegex(), "");
//   // Set the input value to the sanitized value
//   return value;
// };

export function parseJwt(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

export const setError = (retVal) => {
  return retVal
    ? getTranslations(`retval_${retVal}`, "")
    : getTranslations("error_page_title", "Something went wrong");
};

export const getObjectsFirstItem = (object) => {
  if (typeof object !== "object") return false;
  return Object.keys(object)?.length ? Object.keys(object)[0] : false;
};

export const getWebviewLink = () => {
  let routes = window.location.pathname.split("/");
  routes.splice(2, 0, "webview");
  return window.location.origin + routes.join("/");
};

export const getRandomElement = (array) => {
  return array?.[Math.floor(Math.random() * array?.length)];
};

export const colorConsoleLog = (value) => {
  const colors = [
    "red",
    "orange",
    "yellow",
    "green",
    "blue",
    "indigo",
    "violet",
  ];
  console.log(`%c${value}`, `color: ${getRandomElement(colors)}`);
};

export function ordinal_suffix_of(i) {
  var j = i % 10,
    k = i % 100;
  if (j == 1 && k != 11) {
    return i + "st";
  }
  if (j == 2 && k != 12) {
    return i + "nd";
  }
  if (j == 3 && k != 13) {
    return i + "rd";
  }
  return i + "th";
}
