import React, { Suspense, lazy } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Routes,
  useRouteError,
} from "react-router-dom";
import { ErrorToastProvider, LoaderProvider } from "../context";
import { PageLoader } from "../ReusableComponents";

const App = lazy(() => import(/* webpackChunkName: 'app' */ "../views/app"));
const Home = lazy(() => import(/* webpackChunkName: 'home' */ "../views/home"));
const ComingSoon = lazy(() =>
  import(/* webpackChunkName: 'comingsoon' */ "../views/comingsoon")
);

const router = createBrowserRouter(
  [
    {
      element: <App />,
      path: "/",
      errorElement: <ErrorBoundary />,
      children: [
        {
          element: <Home />,
          index: true,
          errorElement: <ErrorBoundary />,
        },
      ],
    },
    // {
    //   element: <ComingSoon />,
    //   path: "/",
    //   errorElement: <ErrorBoundary />,
    // },
  ]
  // { basename: "/" }
);
function ErrorBoundary() {
  let error = useRouteError();
  console.error(error);
  // Uncaught ReferenceError: path is not defined
  return <>error page come here...</>;
}

function RouteConfig() {
  return (
    <Suspense
      fallback={
        <div className="master__wrap">
          <PageLoader />
        </div>
      }
    >
      <LoaderProvider>
        <ErrorToastProvider>
          <RouterProvider router={router} />
        </ErrorToastProvider>
      </LoaderProvider>
    </Suspense>
  );
}

export default RouteConfig;
