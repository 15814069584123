import React from "react";
import { IMAGE_BASE_URL, IMAGE_VERSION } from "../../Common/constants";

const OurWorkCard = ({
  portfolioTitle,
  portfolioCaption,
  portfolioLink,
  portfolioThumb,
}) => {
  return (
    <>
      <div className="portfolio__grid">
        <div className="portfolio__grid-lhs">
          <div className="portfolio__card">
            <h2 className="portfolio__title">{portfolioTitle}</h2>
            <p className="portfolio__caption">{portfolioCaption}</p>
            <a
              className="portfolio__url"
              href={portfolioLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              {portfolioLink}
            </a>
          </div>
        </div>
        <div className="portfolio__grid-rhs">
          <div className="portfolio__thumb">
            <img
              src={`${IMAGE_BASE_URL}/ourWork/${portfolioThumb}?v=${IMAGE_VERSION}`}
              alt=""
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export { OurWorkCard };
