/* Gameplay */
export const GAME_MAX_ATTEMPTS = 6;
export const GAME_IN_PROGRESS = "in-progress";
export const GAME_WON = "won";
export const GAME_LOST = "lost";

export const INVALID_WORD_TOAST_TIMEOUT = 2000;
export const ERROR_TOAST_TIMEOUT = 2000;

/* Authentication */
export const GAMING_LOGIN_COOKIE_NAME = "GTWordle_RAW";
export const WAF_LOGIN_COOKIE_NAME = "_URC";

/* Versions */
export const IMAGE_VERSION = window.imgVersion;
export const JSON_VERSION = process.env.REACT_APP_JSON_VERSION;

/* Paths */
export const IMAGE_BASE_URL = process.env.REACT_APP_IMAGE_BASE_NAME;

/* Security */
export const SECURITY_HEADER = { entity: "$@nt0rYu" };

/* Busters */
export const GAMEPLAY_BUSTER = "wordle-gameplay-buster";
export const MIX_API_BUSTER = "wordle-mix-api-buster";
export const MIX_API_INTERVAL = 20000;

/* Animations */
export const ANIMATION_BOUNCE = "animi__pulse";
export const ANIMATION_SHAKE = "animi__headshake";
export const ANIMATION_FLIP_IN = "animi__flipIn";
export const ANIMATION_FLIP_OUT = "animi__flipOut";

export const DESKTOP = "Desktop";
export const TABLET = "Tablet";
export const MOBILE = "Mobile";

export const LANDSCAPE = "Landscape";
export const PORTRAIT = "Portrait";

export const ONE_SECOND = 1000;
export const ONE_MINUTE = ONE_SECOND * 60;
export const ONE_HOUR = ONE_MINUTE * 60;
export const ONE_DAY = ONE_HOUR * 24;
