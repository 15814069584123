import React, { useEffect, useState } from "react";
import { INVALID_WORD_TOAST_TIMEOUT } from "../../Common/constants";

const ToastMessage = ({ toastText, toastType }) => {
  const [isActive, setIsActive] = useState(false);
  useEffect(() => {
    setIsActive(true);
    setTimeout(() => {
      setIsActive(false);
    }, INVALID_WORD_TOAST_TIMEOUT);
  }, []);

  function toastCls(type) {
    switch (type) {
      case "success":
        return "success";
      case "danger":
        return "danger";
      default:
        return "";
    }
  }

  return (
    <>
      <div
        className={`toastMsg__wrap ${isActive ? "active" : ""} ${toastCls(
          toastType
        )}`}
      >
        <div className="toastMsg__text">{toastText}</div>
      </div>
    </>
  );
};

export { ToastMessage };
