import React from "react";

const PageLoader = () => {
  return (
    <>
      <div className={`pageLoader__wrap`}>
        <div className="pageLoader__box">
          <div className="pageLoader__icon"></div>
          <div className="pageLoader__title">Loading</div>
        </div>
      </div>
    </>
  );
};

export { PageLoader };
