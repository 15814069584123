import { createContext, useContext, useState } from "react";
import { PageLoader } from "../ReusableComponents";

const LoaderContext = createContext({
  loading: false,
  setLoading: () => {},
});

export function LoaderProvider({ children }) {
  const [loading, setLoading] = useState(false);
  const value = { loading, setLoading };
  return (
    <LoaderContext.Provider value={value}>
      {loading && <PageLoader isNoBG={true} />}
      {children}
    </LoaderContext.Provider>
  );
}

export function useLoading() {
  const context = useContext(LoaderContext);
  if (!context) {
    throw new Error("useLoading must be used within LoaderProvider");
  }
  return context;
}
