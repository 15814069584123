import React, { useRef } from "react";
// import { IMAGE_BASE_URL, IMAGE_VERSION } from "../../Common/constants";
import { CtaButton } from "../Buttons";
import { isMobile } from "../../Common/utils";
import Lottie from "lottie-react";
import sunrise from "../../lottiefiles/sunrise.json";
import sunriseMobile from "../../lottiefiles/sunrise_mobile.json";
import { Anchor } from "../Anchor";
const HeroBanner = () => {
  const isPhone = useRef(isMobile());
  return (
    <>
      <section className="hero__wrap">
        <div className="hero__thumb">
          {!isPhone.current && <Lottie animationData={sunrise} loop={false} />}
          {isPhone.current && (
            <Lottie animationData={sunriseMobile} loop={false} />
          )}
        </div>
        <h1 className="hero__title">
          <span>Start Your Journey</span>
        </h1>
        <div className="hero__cta">
          <Anchor
            linkTarget={"_self"}
            hyperLink="#aboutUs"
            linkCls="btn btn__primary"
            linkText="Get Started"
          />
        </div>
      </section>
    </>
  );
};

export { HeroBanner };
