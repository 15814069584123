import React from "react";

const SectionDetails = ({ customCls, pageHeading, pageTitle, pageCaption }) => {
  return (
    <>
      <div className={`section__page ${customCls ? customCls : ""}`}>
        {pageHeading && (
          <h2 className="section__page-heading">{pageHeading}</h2>
        )}
        {pageTitle && <h3 className="section__page-title">{pageTitle}</h3>}
        {pageCaption && (
          <div className="section__page-caption">{pageCaption}</div>
        )}
      </div>
    </>
  );
};

export { SectionDetails };
