import React from "react";

const InputField = ({
  extraCls,
  inputID,
  inputType,
  placeholderLabel,
  isDisabled = false,
  inputText = "",
  handleInput,
  extraAttri = {},
}) => {
  return (
    <>
      <div className={`input ${extraCls ? extraCls : ""}`}>
        <input
          type={inputType}
          id={inputID}
          placeholder={placeholderLabel}
          spellCheck="false"
          disabled={isDisabled}
          value={inputText}
          onChange={handleInput}
          {...extraAttri}
        />
      </div>
    </>
  );
};

export { InputField };
