import React from "react";

const ServiceCard = ({ iconCls, cardSrNo, cardTitle, cardSubmission }) => {
  return (
    <>
      <div className="service__card">
        <div className="service__card-top">
          <div className="service__card-icon">
            <i className={`pa-${iconCls}`}></i>
          </div>
          <div className="service__card-counter">{cardSrNo}</div>
        </div>
        <div className="service__card-mid">
          <h2
            className="service__card-title"
            dangerouslySetInnerHTML={{
              __html: cardTitle,
            }}
          ></h2>
        </div>
        <div className="service__card-bot">
          <div className="service__card-submission">{cardSubmission}</div>
        </div>
      </div>
    </>
  );
};

export { ServiceCard };
